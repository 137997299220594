import React from 'react';
import SimpleInput from 'src/components/design-system/form/input';
import Phone from 'src/components/design-system/form/phone';
import FormLine from 'src/components/design-system/form/line';
import FormSection from 'src/components/design-system/form/section';

const FamilleFormFragment = ({ formData, setFormData, errors }) => {
  const updateTextField = (fieldName) => (e) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });
  };

  return (
    <div className="mt-6 sm:mt-5">
      <FormSection>Parent 1</FormSection>
      <FormLine label="Prénom" htmlFor="parent1_prenom" isRequired>
        <SimpleInput
          id="parent1_prenom"
          value={formData.parent1_prenom}
          onChange={updateTextField('parent1_prenom')}
          errors={errors}
          required
        />
      </FormLine>
      <FormLine label="Nom" htmlFor="parent1_nom" isRequired>
        <SimpleInput
          id="parent1_nom"
          value={formData.parent1_nom}
          onChange={updateTextField('parent1_nom')}
          errors={errors}
        />
      </FormLine>
      <FormSection>Parent 2</FormSection>
      <FormLine label="Prénom" htmlFor="parent2_prenom">
        <SimpleInput
          id="parent2_prenom"
          value={formData.parent2_prenom}
          onChange={updateTextField('parent2_prenom')}
          errors={errors}
        />
      </FormLine>
      <FormLine label="Nom" htmlFor="parent2_nom">
        <SimpleInput
          id="parent2_nom"
          value={formData.parent2_nom}
          onChange={updateTextField('parent2_nom')}
          errors={errors}
        />
      </FormLine>
      <FormSection>Contact</FormSection>
      <FormLine
        label="E-mail principal"
        isRequired
        help="Cet e-mail sert aussi à l'identification sur le site web."
        htmlFor="mail_principal"
      >
        <SimpleInput
          id="mail_principal"
          placeholder="parent1@email.com"
          value={formData.mail_principal}
          onChange={updateTextField('mail_principal')}
          errors={errors}
        />
      </FormLine>
      <FormLine label="E-mail secondaire" htmlFor="mail_secondaire">
        <SimpleInput
          id="mail_secondaire"
          placeholder="parent2@email.com"
          value={formData.mail_secondaire}
          onChange={updateTextField('mail_secondaire')}
          errors={errors}
        />
      </FormLine>
      <FormLine label="Téléphone principal" htmlFor="tel_principal" isRequired>
        <Phone
          id="tel_principal"
          value={formData.tel_principal}
          onChange={updateTextField('tel_principal')}
          errors={errors}
          errors={errors}
        />
      </FormLine>
      <FormLine label="Téléphone secondaire" htmlFor="tel_secondaire">
        <Phone
          id="tel_secondaire"
          value={formData.tel_secondaire}
          onChange={updateTextField('tel_secondaire')}
          errors={errors}
        />
      </FormLine>
      <FormSection>Adresse</FormSection>
      <FormLine label="Adresse" help="" htmlFor="adresse" isRequired>
        <SimpleInput
          id="adresse"
          value={formData.adresse}
          onChange={updateTextField('adresse')}
          errors={errors}
        />
      </FormLine>
      <FormLine label="Code postal" help="" htmlFor="code_postal" isRequired>
        <SimpleInput
          id="code_postal"
          value={formData.code_postal}
          onChange={updateTextField('code_postal')}
          errors={errors}
        />
      </FormLine>
      <FormLine label="Ville" help="" htmlFor="ville" isRequired>
        <SimpleInput
          id="ville"
          value={formData.ville}
          onChange={updateTextField('ville')}
          errors={errors}
        />
      </FormLine>
    </div>
  );
};

export default FamilleFormFragment;
