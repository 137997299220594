import React from 'react';
import qs from 'qs';

import ErrorAlert from 'src/components/design-system/alerts/error';
import Submit from 'src/components/design-system/buttons/submit';
import FormFooter from 'src/components/design-system/form/footer';
import useForm from 'src/hooks/useForm';
import routes from 'src/config/routes';

import FamilleFormFragement from './formFragments/famille';
import ReducFormFragment from './formFragments/reduc';
import ConsentFormFragment from './formFragments/consent';
import EnfantsForm from './formFragments/enfants';

import Header from './header';

const defaultFormValue = {
  reduc: false,
  reglement: false,
  cnil: false,
  parent1_nom: '',
  parent1_prenom: '',
  parent2_nom: '',
  parent2_prenom: '',
  mail_principal: '',
  mail_secondaire: '',
  tel_principal: '',
  tel_secondaire: '',
  adresse: '',
  code_postal: '',
  ville: '',
  commentaire: '',
  enfants: [
    /* 
          nom: '',
          prenom: '',
          vaccination: 'non',
          sexe: '',
          creneaux_id: undefined
          sessions_id: undefined,
         */
  ],
};

const Informations = ({ creneaux, sessions, prebook }) => {
  const { formData, setFormData, requestState, submitForm } = useForm({
    initialValue: { ...defaultFormValue, ...prebook } || defaultFormValue,
    axiosConfig: {
      method: 'POST',
      url: routes.prebook(),
    },
    redirectUrl: (data) =>
      `/inscription/recap?${qs.stringify({ id: data.data.id })}`,
  });

  const errors =
    requestState.status === 'error' ? requestState.error.errors : {};

  return (
    <form onSubmit={submitForm}>
      {requestState.status === 'error' && (
        <ErrorAlert
          className="mt-4"
          message="Certaines informations sont manquantes ou invalides."
        />
      )}

      <Header title="Parents"></Header>
      <div>
        <div className="border-gray-200">
          <FamilleFormFragement
            formData={formData}
            setFormData={setFormData}
            errors={errors}
          />
        </div>
      </div>
      <ReducFormFragment
        formData={formData}
        setFormData={setFormData}
        errors={errors}
      />

      <Header
        title="Enfants et créneaux"
        className="mt-8 border-t border-gray-200"
      />

      <EnfantsForm
        formData={formData}
        setFormData={setFormData}
        requestState={requestState}
        creneaux={creneaux}
        sessions={sessions}
      />

      <Header
        title="Mentions légales"
        className="mt-8 border-t border-gray-200"
      />

      <ConsentFormFragment
        formData={formData}
        setFormData={setFormData}
        errors={errors}
      />

      <FormFooter>
        <Submit type="submit" isLoading={requestState.status === 'loading'}>
          Valider
        </Submit>
      </FormFooter>
    </form>
  );
};

export default Informations;
