import React from 'react';
import qs from 'qs';
import Informations from 'src/components/inscription/informations';
import ContentLayout from 'src/components/layouts/content';
import { H2 } from 'src/components/layouts/content/theme';
import Card from 'src/components/design-system/card';
import SEO from 'src/components/seo';

import routes from 'src/config/routes';

import { useAxiosNow } from 'src/hooks/axios/useAxios';

import Loading from 'src/components/design-system/loading';

const Inscription = ({ location }) => {
  const { id } = location.search ? qs.parse(location.search.slice(1)) : {};
  const { requestState } = useAxiosNow({
    url: routes.getAvailableCreneaux(id),
  });

  return (
    <ContentLayout title="Inscription – étape 1 / 4">
      <SEO title="Inscription" />
      <H2>Inscription – étape 1 / 4</H2>
      <Card className="mt-4">
        <Loading requestState={requestState}>
          {(rs) => {
            const {
              data: {
                data: { creneaux, sessions, prebook },
              },
            } = rs;
            return (
              <Informations
                creneaux={creneaux}
                sessions={sessions}
                prebook={prebook}
              />
            );
          }}
        </Loading>
      </Card>
    </ContentLayout>
  );
};

export default Inscription;
